import seoImg from "../../images/services/seo.png";
import webDesignImg from "../../images/services/web-design.png";
import webDevImg from "../../images/services/web-development.png";
import uiuxImg from "../../images/services/ui-ux.png";
import brandImg from "../../images/services/brand-identity.png";
import socialMarketingImg from "../../images/services/social-media-marketing.png";
import socialServicesImg from "../../images/services/social-media-services.png";
import socialMangmentImg from "../../images/services/social-media-managing.png";
import streamingImg from "../../images/services/streaming-services.png";

export const ServData = [
    {
        icon: seoImg,
        title: 'SEO & Marketing',
        details: 'At Social Rise, we are dedicated to boosting your online visibility and driving meaningful growth through our expert <span>SEO and Marketing</span> services.',
    },
    {

        icon: webDesignImg,
        title: 'Web Design',
        details: 'At Social Rise, we specialize in turning visions into visually stunning and functional <span>websites</span>. ',

    },
    {

        icon: webDevImg,
        title: 'Web Development',
        details: 'At Social Rise, we are dedicated to crafting exceptional <span>web experiences</span> tailored to your unique needs.',

    },
    {

        icon: uiuxImg,
        title: 'UI/UX Designing',
        details: 'At Social Rise, we specialize in creating captivating <span>UI</span> and optimizing <span>UX</span> for digital products.',

    },
    {

        icon: brandImg,
        title: 'Brand Identity',
        details: 'At Social Rise, we understand the power of a compelling <span>brand identity</span>. We specialize in creating distinct visual elements.',

    },
    {
        icon: socialMarketingImg,
        title: 'Social Media Marketing',
        details: 'At Social Rise, we are dedicated to amplifying your online presence through strategic <span>Social Media Marketing</span>.',
    },
    {
        icon: socialServicesImg,
        title: 'Social Media Services',
        details: 'At Social Rise, we excel in crafting powerful <span>social media strategies</span> tailored to elevate your brands online presence.',

    },
    {

        icon: socialMangmentImg,
        title: 'Social Media Managing',
        details: 'At Social Rise, we take the hassle out of <span>social media management</span> so you can focus on what you do best – running your business.',

    },
    {

        icon: streamingImg,
        title: 'Streaming Services',
        details: 'At Social Rise, we are passionate about bringing high-quality <span>streaming experiences</span> directly to your screen.',

    },
]