import React from 'react'

export const AboutSection = () => {
    return (
        <section className="about-us position-relative " id="about-us">
            <div className="container">
                <div className="section-title text-center text-md-start">
                    <h2 className='text-capitalize'>about us</h2>
                    <p className='text-white'>Boost Your Business<br />
                        With <span className='fw-bold'>Social</span> Rise Because<br />
                        Your Social Story...<br />
                        Is Our <span className='fw-bold'>Rising</span> Glory</p>
                </div>
                <p className='text-white text-center mt-3 text-md-start'>"Welcome to Social Rise !! We are a dynamic and innovative digital marketing agency dedicated to helping businesses thrive in the Online Landscape. With a Team Of Passionate Experts, we craft tailored strategies that drive results.<br />
                    Our approach combines cutting-edge technology with creative thinking to deliver impactful solutions.
                    At Social Rise, we're not just a service provider,
                    We're Your Dedicated Partner In Digital Success."</p>
            </div>
            <div className="bg-img position-absolute top-0 w-100 h-100"></div>
        </section>
    )
}
