import React from 'react';
import { Form } from "./Form";
import { Link } from 'react-router-dom';

export const ContactSection = () => {
    return (
        <section className="contact-us" id="contact-us">
            <div className="container">
                <div className="row align-items-md-center justify-content-md-between ">
                    <div className="col-md-5 mb-5 mb-md-0">
                        <div className="contact-info text-center text-md-start">
                            <h2>contact us</h2>
                            <p className='text-white text-capitalize'><span className="fw-bold">let's </span>talk about your <span className="fw-bold">information</span></p>
                            <span className='text-white d-block'>call us for immediate support to this number.</span>
                            <Link to="tel:+96181502831" className='clr-primary fw-bold d-block'>+961 81 502 831</Link>
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-6 col-xl-5">
                        <Form />
                    </div>
                </div>
            </div>
        </section>
    )
}
