export const TestimonialData = [
    {
        text: "At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.",
        stars: 5,
        name: "lena",
    },
    {
        text: "At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.",
        stars: 4,
        name: "ali",
    },
    {
        text: "At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.",
        stars: 5,
        name: "rayan",
    },
    {
        text: "At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.At Social Rise, we're dedicated to amplifying your online presence through strategic Social Media Marketing.",
        stars: 4,
        name: "mohammad",
    }
]