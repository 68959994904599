import React, { useState } from 'react';
import { inputData } from './InputData';
import { Input } from './Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSchema } from '../Validation/UserValidation';

export const Form = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset, // Access the reset function from useForm
    } = useForm({
        resolver: yupResolver(userSchema),
    });

    const [successMessage, setSuccessMessage] = useState('');

    const onSubmit = async (data) => {
        try {
            const response = await fetch('http://localhost/socialrise/send-email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data }),
            });

            if (response.ok) {
                // Set success message and reset the form
                setSuccessMessage('Email sent successfully!');
                reset();
            } else {
                console.error('Failed to send email.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {inputData.map((inputField, i) => (
                <div className="inner-form" key={i}>
                    <div className="form-outline position-relative">
                        <Input
                            type={inputField.type}
                            name={inputField.name}
                            id={inputField.id}
                            className="form-control rounded-0"
                            register={register(inputField.name)}
                        />
                        <label
                            className="form-label mb-0 text-white top-50 position-absolute"
                            htmlFor={inputField.name}
                        >
                            {inputField.label}
                        </label>
                    </div>
                    <p className="text-danger mt-2 ms-2 small fw-bold">
                        {errors[inputField.name]?.message}
                    </p>
                </div>
            ))}
            <div className="">
                <input
                    type="submit"
                    value="Submit"
                    className="theme-btn text-uppercase text-white bg-transparent"
                />
            </div>
            <p className="mail-msg">{successMessage}</p>
        </form>
    );
};
