import React from 'react';
import customerImg from "../../images/customer-support.webp";
import { Link } from 'react-scroll';

export const CustomerSupportSection = () => {
    return (
        <section className="customer-support">
            <div id="customer-support">
                <div className="container">
                    <div className="row gy-5 gy-md-0 justify-content-md-between align-items-md-center">
                        <div className="col-md-5">
                            <div className="customer-img">
                                <img src={customerImg} alt="customer img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="customer-support-info text-center text-md-start">
                                <h2 className='text-capitalize'>customer support</h2>
                                <p className='clr-gray'>We’re committed to providing a positive customer experience. Please feel free to reach out to us at<br />
                                    any time. We’ve always happy to help.</p>
                                <Link to='contact-us' className='theme-btn clr-gray ms-auto me-auto ms-md-0 me-md-0' role="button">contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
