import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export const SocialMediaData = [
    {
        icon: faTiktok,
        link: "https://www.tiktok.com/@socialrise.agency?_t=8gkWuCa5Ls2&_r=1",
    },
    {
        icon: faInstagram,
        link: "https://instagram.com/socialrise.agency?igshid=MzRIOBiNWFIZA==",
    },
    {
        icon: faFacebookF,
        link: "https://facebook.com/profile.php?id=61552592703810",
    },
    {
        icon: faWhatsapp,
        link:"https://wa.me/96181502831"
    }
]