import React from 'react';
import { Hero } from './Hero';
import { Services } from './Services';
import { Testimonial } from './Testimonial';
import { Packages } from './Packages';
import { AboutUs } from './AboutUs';
import { CustomerSupport } from './CustomerSupport';
import { Contactus } from './ContactUs';
import { Footer } from './Footer';

export const Home = () => {
    return (
        <>
            <Hero />
            <Services />
            <Testimonial />
            <Packages />
            <AboutUs />
            <CustomerSupport />
            <Contactus />
            <Footer />
        </>
    )
}
