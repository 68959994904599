import React from 'react'
import { NavBar } from '../Components/NavBar/NavBar'
import { Header } from '../Components/Header/Header'

export const Hero = () => {
    return (
        <>
            <NavBar />
            <Header />
        </>
    )
}
