import React from 'react'
import { ServData } from './ServData'
import { ServBox } from './ServBox'

export const ServicesSection = () => {
    return (
        <section className="services" id="services">
            <div className="container">
                <div className="section-title text-center ">
                    <h2 className='text-capitalize'>services</h2>
                    <p className='clr-gray ms-auto me-auto'><span className='fw-bold'>Save</span> Time managing your
                        business with <span className='fw-bold'>Our Services</span> </p>
                </div>
                <div className="row d-grid ps-3 pe-3">
                    {ServData.map((serv, i) => (
                        <div className='box text-center' key={i}>
                            <ServBox icon={serv.icon} title={serv.title} details={serv.details} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
