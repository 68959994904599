import React from 'react'
import { Testimonial } from './Testimonial'

export const TestimonialSection = () => {
    return (
        <section className="testimonials" id="testimonials">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className='text-capitalize'>testimonial</h2>
                    <p className='text-white text-capitalize ms-auto me-auto'>our client feedback</p>
                </div>
                <Testimonial />
            </div>
        </section>
    )
}
