export const inputData = [
    {
        type: "text",
        name: "name",
        label: "Name",
    },
    {
        type: "email",
        name: "email",
        label: "Email",
    },
    {
        type: "text",
        name: "subject",
        label: "Subject",
    },
    {
        type: "textarea",
        name: "message",
        label: "Message",
    },
]