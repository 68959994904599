import React from 'react';

export const Input = (props) => {
    const { type, name, className, register } = props;

    if (type === "textarea") {
        return <textarea type={type} name={name} id={name} {...register} placeholder="" className={className} />;
    } else {
        return <input type={type} name={name} id={name} {...register} placeholder="" className={className} />;
    }
}
