import * as Yup from "yup";

// Define a validation schema for user data using Yup.
export const userSchema = Yup.object().shape({
    name: Yup.string().required("Name is Required"),
    subject: Yup.string().required("Subject is Required"),
    message: Yup.string().required("Message is Required"),
    email: Yup.string()
        .email("Invalid Email Format")
        .required("Email is Required"),
});