import React from 'react'

export const ServBox = (props) => {
  const { icon, title, details } = props;
  return (
    <div className="flip3D ms-auto me-auto ">
      <div className="back">
        <div className="icon text-start">
          <img src={icon} alt={title} className='img-fluid'/>
        </div>
        <h4 className='clr-gray text-start'>{title}</h4>
        <p className='clr-gray text-start'dangerouslySetInnerHTML={{ __html: details }}></p>
      </div>
      <div className="front text-center bg-white ">
        <div className="icon">
          <img src={icon} alt={title} className='img-fluid'/>
        </div>
        <h4 className='fw-bold clr-gray'>{title}</h4>
      </div>
    </div>
  )
}
