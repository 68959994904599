import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

export const SocialMediaLi = (props) => {
    const { icon, link } = props;
    return (
        <li className='social-item d-flex align-items-center justify-content-center'>
            <Link target='_blank' to={link} className='text-black'>
                <FontAwesomeIcon icon={icon} className="social-icon" />
            </Link>
        </li>
    )
}
