import React from 'react';
import logoImg from '../../images/logo.webp';

export const FooterSection = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="info text-center d-flex flex-column align-items-center">
                    <img src={logoImg} alt="social rise logo" />
                    <a href="mailto:support@socialrise.agency" className='clr-primary fw-bold'>support@socialrise.agency</a>
                    <a href='tel:+96181502831' className='clr-primary fw-bold'>+961 81 502 831</a>
                </div>
            </div>
            <div className="copyright text-center text-white">
                <p>&copy; 2023 Copyright all right reserved - Social Rise</p>
            </div>
        </footer>
    )
}
