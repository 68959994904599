import React from 'react'
import { TestimonialData } from './TestimonialData'
import QuoteImg from "../../images/quote.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export const Testimonial = () => {
    return (
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {
                    TestimonialData.map((testimonial, i) => (
                        <div className="carousel-item position-relative bg-white active" key={i} data-bs-interval="4000">
                            <img src={QuoteImg} alt='quote' className='position-absolute quote start-50 translate-middle-x' />
                            <p className='text-center ms-auto me-auto'>{testimonial.text}</p>
                            <span className='stars d-flex align-items-center justify-content-center'>
                                {Array(testimonial.stars).fill().map((_, index) => (
                                    <FontAwesomeIcon key={index} icon={faStar} size="1x" className="star-icon" />
                                ))}
                            </span>
                            <span className='client-name clr-gray text-capitalize text-center ms-auto me-auto d-block'>{testimonial.name}</span>
                        </div>
                    ))
                }

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="arrow-icon d-flex align-items-center justify-content-center" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512"><path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" /></svg>
                </span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="arrow-icon d-flex align-items-center justify-content-center" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" /></svg>
                </span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
