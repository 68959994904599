import basicImg from "../../images/packages/basic.png";
import vipImg from "../../images/packages/vip.png";
import premiumImg from "../../images/packages/premium.png";

export const PackageData = [
    {
        icon: basicImg,
        title: "Basic Package",
        price: "7$",
        details: ["Content Creation", "Graphic Design", "SEO Integration", "Paid Advertising", "Social Audit"]
    },
    {
        icon: vipImg,
        title: "VIP Package",
        price: "25$",
        details: ["Content Creation", "Graphic Design", "Business Plan", "Web Development", "Social Audit"]
    },
    {
        icon: premiumImg,
        title: "Premium Package",
        price: "15$",
        details: ["Content Creation", "Graphic Design", "E-Commerce Integration", "Paid Advertising", "Social Audit"]
    },
]