import React from 'react';
import { PackageData } from './PackageData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const Package = () => {
    return (
        <>
            {PackageData.map((packageItem, i) => (
                <div className='col-12 col-md-6 col-xl-4' key={i}>
                    <div className='package bg-white text-center d-flex align-items-center flex-column'>
                        <div className="package-icon">
                            <img src={packageItem.icon} alt="icon" />
                        </div>
                        <div className="package-title">
                            <h4 className='clr-gray fw-bold'>{packageItem.title}</h4>
                        </div>
                        <div className="price ratio ratio-1x1 rounded-circle d-flex align-items-center justify-content-center text-white ms-auto me-auto">
                            {packageItem.price}
                        </div>
                        <ul className='details list-unstyled mb-0'>
                            {packageItem.details.map((detail, i) => (
                                <li key={i} className='text-start clr-gray mb-3'><FontAwesomeIcon icon={faCheck} className='clr-gray' /> {detail}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
}
