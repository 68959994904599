import React, { useEffect, useState } from 'react'
import heroImg from "../../images/hero-img.webp";
import { Link } from 'react-scroll';
import { SocialMediaData } from './socialMediaData';
import { SocialMediaLi } from './SocialMediaLi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Typed from "react-typed";

export const Header = () => {
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setShowButton(window.scrollY > 500);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header className='header d-flex align-items-center justify-content-center' id="hero">
            <div className="container h-100">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-md-6 col-lg-7 hero-text text-center text-md-start">
                        <span className='text-uppercase text-white'>welcome</span>
                        <h1 className='text-white'>Your <span className="fw-bold">Social</span> Story
                            Is Our <span className="fw-bold">Rising</span> Glory </h1>
                        <p className='text-capitalize text-white'>
                            <Typed
                                className="typed-text"
                                strings={[
                                    "grow your business with us",
                                    "increase your social worth",
                                    "No risk No story",
                                ]}
                                typeSpeed={40}
                                backSpeed={60}
                                loop
                            />
                        </p>
                        <Link to="contact-us" className='text-center theme-btn text-white ms-auto me-auto ms-md-0 me-md-0' role="button">contact us</Link>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="hero-img">
                            <img src={heroImg} alt="social rise hero img" className='img-fluid position-relative' />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="social-media list-unstyled position-fixed">
                {
                    SocialMediaData.map((li, i) => (
                        <SocialMediaLi key={i} icon={li.icon} link={li.link} />
                    ))
                }
            </ul>
            <Link to='hero'>
                <FontAwesomeIcon icon={faChevronUp} className={`up position-fixed ${showButton ? 'show' : ''}`} />
            </Link>
        </header>
    )
}
