import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './Pages/Home';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <div className="App">
      <Router>
        <HelmetProvider>
          <Helmet>
            <title>Social Rise Agency</title>
            <meta
              name="description"
              content="Social Rise Agency is an innovative social media agency based in Lebanon that helps clients grow their businesses online. So shall we talk?"
            />
            <meta name="keywords"
              content="Social Media Marketing, Online Business Growth, Digital Marketing Services,Social Media Management,Innovative Marketing Solutions,Lebanon Social Media Agency,Business Growth Strategies,Social Media Consultancy,Online Branding,Digital Advertising,Social Media Optimization,Creative Marketing Agency,Social Media Campaigns,Lebanon Business Marketing,Social Media Solutions,Coding,Web development"
            />
            <meta name="author" content="Mohammad Salimi" />
            <meta name="copyright" content="Social Rise Agency" />
            <meta property="og:title" content="Social Rise Agency" />
            <meta property="og:site_name" content="Social Rise" />
            <meta property="og:title" content="Social Rise Agency in Lebanon" />
            <meta property="og:description" content="Social Rise Agency is an innovative social media agency based in Lebanon that helps clients grow their businesses online. So shall we talk?" />
            <meta property="og:url" content="https://www.socialrise.agency" />
          </Helmet>
        </HelmetProvider>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>

      </Router>
    </div>
  );
}

export default App;
