import React, { useEffect, useState } from 'react';
import { Link } from "react-scroll";
import socialRiseLogo from "../../images/logo.webp";

export const NavBar = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 600) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <nav className={`navbar navbar-expand-lg fixed-top ${isExpanded ? 'expanded' : ''} ${isScrolled ? 'isScrolled' : ''}`}>
            <div className="container">
                <a className="navbar-brand d-lg-none" href="#">
                    <img src={socialRiseLogo} alt="Social Rise Logo" className='logo' />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isExpanded} aria-label="Toggle navigation" onClick={handleToggle}>
                    <svg
                        stroke="#fff"
                        fill="none"
                        className="hamburger"
                        viewBox="-10 -10 120 120"
                        width="40"
                    >
                        <path
                            className="line"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 30 v 70"
                        ></path>
                    </svg>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav w-100 mb-2 mb-lg-0 d-lg-flex align-items-lg-center justify-content-lg-between">
                        <li className="nav-item">
                            <Link to="hero"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={0}
                                duration={500}
                                activeClass="active"
                                className="nav-link text-capitalize" aria-current="page">home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="services"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={20}
                                duration={500}
                                activeClass="active"
                                className="nav-link text-capitalize">services</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="testimonials"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={50}
                                duration={500}
                                activeClass="active"
                                className="nav-link text-capitalize">testimonial</Link>
                        </li>
                        <li className="nav-item d-none d-lg-block">
                            <img src={socialRiseLogo} alt="Social Rise Logo" className='logo' />
                        </li>
                        <li className="nav-item">
                            <Link to="packages"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={50}
                                duration={500}
                                activeClass="active"
                                className="nav-link text-capitalize">packages</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="about-us"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={50}
                                duration={500}
                                activeClass="active"
                                className="nav-link text-capitalize">about us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="contact-us"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={0}
                                duration={500}
                                activeClass="active"
                                className="nav-link text-capitalize">contact us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
