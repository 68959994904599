import React from 'react'
import { Package } from './Package'

export const PackageSection = () => {
    return (
        <section className="packages" id="packages">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className='text-capitalize clr-primary'>packages</h2>
                    <p className='text-capitalize clr-gray ms-auto me-auto fw-bold'>the best price just for you</p>
                </div>
                <div className="row g-xl-0">
                    <Package />
                </div>
                <p className='clr-gray fw-bold text-center quote'>“It's Not What You Sell That Matters As Much As How You Sell It!” ...
                </p>
            </div>
        </section>
    )
}
